<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Downloads</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- downloads area start -->
      <section class="downloads__area pt-30 pb-150 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-7 d-none d-lg-block">
                  <p v-html="sourceDownloads.descricao"></p>
               </div>
               <div class="col-lg-5">
                  <div class="input-group">
                     <i class="far fa-search input-group-text" @click="searchDownloads"></i>
                     <input type="text" class="form-control" placeholder="Buscar" v-model="search.valor" @keyup.enter="searchDownloads">
                  </div>
               </div>

               <div class="col-12 mt-30 mb-30">
                  <div class="product__details-des-tab tab-download">
                     <ul class="nav nav-tabs justify-content-center" id="productDesTab" role="tablist">
                        <li v-if="search.result.manuais.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-one" data-bs-target="#one" data-bs-toggle="tab" type="button" role="tab" aria-controls="one"
                              :class="tabAtivo == 'tab-one' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-one' > 0 ? true : false">
                              <span class="limitador">Manuais</span>
                           </button>
                        </li>
                        <li v-if="search.result.folhetos.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-two" data-bs-target="#two" data-bs-toggle="tab" type="button" role="tab" aria-controls="two"
                              :class="tabAtivo == 'tab-two' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-two' ? true : false">
                              <span class="limitador">Folhetos</span>
                           </button>
                        </li>
                        <li v-if="search.result.catalogos.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-three" data-bs-target="#three" data-bs-toggle="tab" type="button" role="tab" aria-controls="three"
                              :class="tabAtivo == 'tab-three' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-three' ? true : false">
                              <span>Catálogos</span>
                           </button>
                        </li>
                        <li v-if="search.result.revistas.length > 0 && logado.cliente" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-four" data-bs-target="#four" data-bs-toggle="tab" type="button" role="tab" aria-controls="four"
                              :class="tabAtivo == 'tab-four' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-four' ? true : false">
                              <span class="limitador">Revista Canal+</span>
                           </button>
                        </li>
                        <li v-if="(search.result.apoio.manuais.length > 0 || search.result.apoio.paineis.length > 0 || search.result.apoio.banners.length > 0 || search.result.apoio.logos.length > 0) && logado.cliente" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-five" data-bs-target="#five" data-bs-toggle="tab" type="button" role="tab" aria-controls="five"
                              :class="tabAtivo == 'tab-five' ? 'active' : ''" :aria-selected="tabAtivo == 'tab-five' ? true : false">
                              <span class="limitador">Apoio PDV</span>
                           </button>
                        </li>
                     </ul>
                  </div>
               </div>

               <div class="col-12 mb-30" v-if="tabAtivo != 'tab-error'">
                  <div class="tab-content" id="prodductDesTaContent">
                     <div class="tab-pane fade" id="one" role="tabpanel" aria-labelledby="tab-one" :class="tabAtivo == 'tab-one' ? 'show active' : ''"
                        v-if="search.result.manuais.length > 0">
                        <div class="cards-170 cards-sm-200 cards-xl-225">
                           <arquivo v-for="(arquivo, index) in search.result.manuais" :key="'Manuais'+ index" :arquivo="arquivo" :view="'Downloads-Manuais'" 
                              @download="download($event)" />
                        </div>
                     </div>
                     <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="tab-two" :class="tabAtivo == 'tab-two' ? 'show active' : ''"
                        v-if="search.result.folhetos.length > 0">
                        <div class="cards-170 cards-sm-200 cards-xl-225">
                           <arquivo v-for="(arquivo, index) in search.result.folhetos" :key="'Folhetos'+ index" :arquivo="arquivo" :view="'Downloads-Folhetos'" 
                              @download="download($event)" />
                        </div>
                     </div>
                     <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="tab-three" :class="tabAtivo == 'tab-three' ? 'show active' : ''"
                        v-if="search.result.catalogos.length > 0">
                        <div class="cards-170 cards-sm-200 cards-xl-225">
                           <arquivo v-for="(arquivo, index) in search.result.catalogos" :key="'Catalogos'+ index" :arquivo="arquivo" :view="'Downloads-Catalogos'" 
                              @download="download($event)" />
                        </div>
                     </div>
                     <div class="tab-pane fade" id="four" role="tabpanel" aria-labelledby="tab-four" :class="tabAtivo == 'tab-four' ? 'show active' : ''"
                        v-if="search.result.revistas.length > 0 && logado.cliente">
                        <div class="row mb-40">
                           <div class="col-12 mb-30">
                              <p class="info-header" v-html="sourceDownloads.revistas_descricao"></p>
                           </div>
                           <div class="col-12 mb-10">
                              <div class="cards-170 cards-sm-200 cards-xl-225">
                                 <arquivo v-for="(arquivo, index) in search.result.revistas" :key="'Revistas'+ index" :arquivo="arquivo" :view="'Downloads-Revistas'" 
                                    @download="download($event)" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="five" role="tabpanel" aria-labelledby="tab-five" :class="tabAtivo == 'tab-five' ? 'show active' : ''"
                        v-if="(search.result.apoio.manuais.length > 0 || search.result.apoio.paineis.length > 0 || search.result.apoio.banners.length > 0 || search.result.apoio.logos.length > 0) && logado.cliente">
                        <div class="row mb-30">
                           <div class="col-12">
                              <p class="info-header" v-html="sourceDownloads.apoio_descricao"></p>
                           </div>
                        </div>

                        <div class="row mb-40" v-if="search.result.apoio.manuais.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16 weight-500"><i class="fal fa-books font-14 me-1"></i> Manual da marca</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="row">
                                 <div class="col-12 mb-2">
                                    <div class="card card-title grey-bg">
                                       <div class="card-body">
                                          <div class="row align-items-center">
                                             <div class="wpx-100"></div>
                                             <div class="col-5 text-start"><i class="far fa-hashtag font-11 me-1"></i> Nome</div>
                                             <div class="col d-none d-sm-block"><i class="far fa-stream font-10 me-1 "></i> Tipo</div>
                                             <div class="col d-none d-md-block"><i class="far fa-expand font-10 me-1"></i> Tamanho</div>
                                             <div class="col d-none d-xl-block"><i class="far fa-expand-alt font-10 me-1"></i> Dimensões</div>
                                             <div class="col"><i class="far fa-link font-10 me-1"></i> Link</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <arquivo v-for="(arquivo, index) in search.result.apoio.manuais" :key="'ManualMarca'+ index" :arquivo="arquivo" :view="'Downloads-ManualMarca'" 
                                    @download="download($event)" />
                              </div>
                           </div>
                        </div>
                        <div class="row mb-40" v-if="search.result.apoio.logos.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16 weight-500"><i class="fal fa-copyright font-14 me-1"></i> Logotipos</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="row">
                                 <div class="col-12 mb-2">
                                    <div class="card card-title grey-bg">
                                       <div class="card-body">
                                          <div class="row align-items-center">
                                             <div class="wpx-100"></div>
                                             <div class="col-5 text-start"><i class="far fa-hashtag font-11 me-1"></i> Nome</div>
                                             <div class="col d-none d-sm-block"><i class="far fa-stream font-10 me-1 "></i> Tipo</div>
                                             <div class="col d-none d-md-block"><i class="far fa-expand font-10 me-1"></i> Tamanho</div>
                                             <div class="col d-none d-xl-block"><i class="far fa-expand-alt font-10 me-1"></i> Dimensões</div>
                                             <div class="col"><i class="far fa-link font-10 me-1"></i> Link</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <arquivo v-for="(arquivo, index) in search.result.apoio.logos" :key="'Logos'+ index" :arquivo="arquivo" :view="'Downloads-Logos'" 
                                    @download="download($event)" />
                              </div>
                           </div>
                        </div>
                        <div class="row mb-40" v-if="search.result.apoio.banners.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16 weight-500"><i class="fal fa-image font-14 me-1"></i> Banners</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="row">
                                 <div class="col-12 mb-2">
                                    <div class="card card-title grey-bg">
                                       <div class="card-body">
                                          <div class="row align-items-center">
                                             <div class="wpx-100"></div>
                                             <div class="col-5 text-start"><i class="far fa-hashtag font-11 me-1"></i> Nome</div>
                                             <div class="col d-none d-sm-block"><i class="far fa-stream font-10 me-1 "></i> Tipo</div>
                                             <div class="col d-none d-md-block"><i class="far fa-expand font-10 me-1"></i> Tamanho</div>
                                             <div class="col d-none d-xl-block"><i class="far fa-expand-alt font-10 me-1"></i> Dimensões</div>
                                             <div class="col"><i class="far fa-link font-10 me-1"></i> Link</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <arquivo v-for="(arquivo, index) in search.result.apoio.banners" :key="'Banners'+ index" :arquivo="arquivo" :view="'Downloads-Banners'" 
                                    @download="download($event)" />
                              </div>
                           </div>
                        </div>
                        <div class="row mb-40" v-if="search.result.apoio.paineis.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16 weight-500"><i class="fal fa-presentation font-14 me-1"></i> Painéis</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="row">
                                 <div class="col-12 mb-2">
                                    <div class="card card-title grey-bg">
                                       <div class="card-body">
                                          <div class="row align-items-center">
                                             <div class="wpx-100"></div>
                                             <div class="col-5 text-start"><i class="far fa-hashtag font-11 me-1"></i> Nome</div>
                                             <div class="col d-none d-sm-block"><i class="far fa-stream font-10 me-1 "></i> Tipo</div>
                                             <div class="col d-none d-md-block"><i class="far fa-expand font-10 me-1"></i> Tamanho</div>
                                             <div class="col d-none d-xl-block"><i class="far fa-expand-alt font-10 me-1"></i> Dimensões</div>
                                             <div class="col"><i class="far fa-link font-10 me-1"></i> Link</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <arquivo v-for="(arquivo, index) in search.result.apoio.paineis" :key="'Paineis'+ index" :arquivo="arquivo" :view="'Downloads-Paineis'" 
                                    @download="download($event)" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-12" v-else>
                  <div class="error__content text-center">
                     <div class="error__number">
                        <h1 class="mt-50 mb-50"><i class="far fa-search"></i></h1>
                     </div>
                     <span class="d-none d-sm-block">Sua pesquisa não encontrou nenhum item correspondente.</span>
                     <h4 class="mt-3 mb-4">Nenhum item encontrado.</h4>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import arquivo from '@/components/downloads/Arquivo.vue'

export default {
   name: 'Downloads',
   data : function () {
      return {
         tabAtivo: 'tab-one',
         search: {'valor': '', 'isTyping': false, 'result': {'manuais': [], 'folhetos': [], 'catalogos': [], 'revistas': [], 'apoio': {'manuais': [], 'paineis': [], 'banners': [], 'logos': []}}}
      }
   },
   computed: {
		... mapState({
         sourceDownloads: state => state.source.downloads,
			urlRest: state => state.urlRest,
			logado: state => state.logado
		})
	},
   components: {
      arquivo
   },
   methods: {
      searchDownloads : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/searchDownloads',
            params: {
               valor: this.search.valor
            }
			}).then(response => {
            this.search.result = response.data

            if (response.data.manuais.length > 0) {
               this.tabAtivo = 'tab-one';
            } else {
               if (response.data.folhetos.length > 0) {
                  this.tabAtivo = 'tab-two';
               } else {
                  if (response.data.catalogos.length > 0) {
                     this.tabAtivo = 'tab-three';
                  } else {
                     if (response.data.revistas.length > 0) {
                        this.tabAtivo = 'tab-four';
                     } else if (response.data.apoio.manuais.length > 0 || 
                        response.data.apoio.paineis.length > 0 || 
                        response.data.apoio.banners.length > 0 || 
                        response.data.apoio.logos.length > 0) {
                        this.tabAtivo = 'tab-five';
                     } else {
                        this.tabAtivo = 'tab-error';
                     }
                  }
               }
            }
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      download : function (arquivo) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: arquivo.urlDownload,
            responseType: 'blob'

         }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', arquivo.titulo);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   },
   mounted() {
      this.searchDownloads()
   }
}

</script>

<style scoped>

#prodductDesTaContent .card-title .card-body {
   padding: 0px 12px 8px !important;
}

#prodductDesTaContent .info-header {
   text-align: center;
   width: 767px;
   max-width: 100%;
   margin: 0 auto;
}

.downloads__area .input-group .input-group-text {
   background: #fff;
   border-color: #ebebeb;
   color: #aaa;
   font-size: 13px;
   padding-top: 13px;
   transform: none;
   cursor: pointer;
}

.downloads__area .input-group .form-control {
   border-left: 0;
}

</style>